import {useEffect, useState} from 'react'
import { FormContainer } from '../../components'
import { useForm } from 'react-hook-form'
import useProfilesHook from '../../api/profiles'
import useUploadHook from '../../api/upload'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  InputFile,
  InputPassword,
  InputTel,
  InputText,
  InputTextArea,
} from '../../utils/dynamicForm'
import { Spinner } from '../../components'
import { useMessage } from '../../context/AdminMessageContext'; // Import message context

const MAX_FILE_SIZE_MB = 1; // Set max file size limit to 1MB
const MyProfile = () => {
  const [fileLink, setFileLink] = useState(null)
  const { setErrorMessage } = useMessage();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const { getMyProfile, postMyProfile } = useProfilesHook({
    page: 1,
    q: '',
    limit: 25,
  })
  const { postUploadProfileImage } = useUploadHook()

  const { data, isLoading} = getMyProfile
  const {
    data: dataUpload,
    isLoading: isLoadingUpload,
    mutateAsync: mutateAsyncUpload,
    isSuccess: isSuccessUpload,
  } = postUploadProfileImage

  const {
    isLoading: isLoadingPost,
    mutateAsync,
  } = postMyProfile

  // Set initial profile data into the form
  useEffect(() => {
    setValue('name', !isLoading ? data && data.name : '')
    setValue('address', !isLoading ? data && data.address : '')
    setValue('phone', !isLoading ? data && data.phone : '')
    setValue('bio', !isLoading ? data && data.bio : '')
  }, [isLoading, setValue, data])


  // Handle file selection, check size, and upload
  const handleFileChange = async (selectedFile) => {
    if (!selectedFile) return;

    const fileSizeMB = selectedFile.size / (1024 * 1024); // Convert file size to MB
    if (fileSizeMB > MAX_FILE_SIZE_MB) {
      setErrorMessage(`File size exceeds ${MAX_FILE_SIZE_MB}MB limit. Please upload a smaller file.`);
      return;
    }

    // If file size is valid, upload the file
    const formData = new FormData();
    formData.append('file', selectedFile);
    try {
      await mutateAsyncUpload({ type: 'image', formData });
      if (isSuccessUpload && dataUpload?.filePaths?.[0]?.path) {
        setFileLink(dataUpload.filePaths[0].path);
      }
    } catch (error) {
      setErrorMessage('File upload failed. Please try again.');
    }
  };



  const submitHandler = (data) => {
    if (!fileLink) {
      mutateAsync({
        name: data.name,
        phone: data.phone,
        address: data.address,
        bio: data.bio,
        password: data.password,
      })
    } else {
      mutateAsync({
        name: data.name,
        phone: data.phone,
        address: data.address,
        bio: data.bio,
        password: data.password,
        image: fileLink,
      })
    }
  }

  return (
      <FormContainer>
        <HelmetProvider>
          <Helmet>
            <title>UserProfile</title>
            <meta property='og:title' content='UserProfile' key='title' />
          </Helmet>
        </HelmetProvider>
        <h3 className='fw-light font-monospace text-center'>Account</h3>

        {isLoading && <Spinner />}
        <form onSubmit={handleSubmit(submitHandler)}>
          {data && data.image && (
              <div className='d-flex justify-content-center'>
                <img
                    src={data && data.image}
                    alt='avatar'
                    className='rounded-circle'
                    width='200'
                    height='auto'
                />
              </div>
          )}

          <div className='row'>
            <div className='col-12'>
              {InputText({
                register,
                errors,
                label: 'Name',
                name: 'name',
                placeholder: 'Name',
              })}
            </div>
            <div className='col-md-6 col-12'>
              {InputText({
                register,
                errors,
                label: 'Address',
                name: 'address',
                placeholder: 'Address',
              })}
            </div>
            <div className='col-md-6 col-12'>
              {InputTel({
                register,
                errors,
                label: 'Phone',
                name: 'phone',
                placeholder: '+252 (61) 530-1507',
              })}
            </div>
            <div className='col-12'>
              {InputTextArea({
                register,
                errors,
                label: 'Bio',
                name: 'bio',
                placeholder: 'Tell us about yourself',
              })}
            </div>

            <div className='col-12'>
              {InputFile({
                register,
                errors,
                label: 'Image',
                name: 'image',
                setFile: handleFileChange,
                isRequired: false,
                placeholder: 'Choose an image',
              })}
            </div>
            <div className='col-md-6 col-12'>
              {InputPassword({
                register,
                errors,
                label: 'Password',
                name: 'password',
                minLength: true,
                isRequired: false,
                placeholder: "Leave blank if you don't want to change",
              })}
            </div>
            <div className='col-md-6 col-12'>
              {InputPassword({
                register,
                errors,
                watch,
                name: 'confirmPassword',
                label: 'Confirm Password',
                validate: true,
                minLength: true,
                isRequired: false,
                placeholder: 'Confirm Password',
              })}
            </div>
          </div>

          <button
              type='submit'
              className='btn btn-primary form-control'
              disabled={isLoadingPost || isLoadingUpload}
          >
            {isLoadingPost || isLoadingUpload ? (
                <span className='spinner-border spinner-border-sm' />
            ) : (
                'Update'
            )}
          </button>
        </form>
      </FormContainer>
  )
}

export default MyProfile
