import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SpaceMap from "../../components/maps/space-map";
import SpaceForm from "../../components/maps/space-form";
import { Grid, Button } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import dynamicAPI from '../../api/dynamicAPI';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';

function Maps() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const region = params.get('region') || ''; // Extract the region parameter


    const url = '/api/view/spaces';
    const queryKey = ['Spaces', region];
    const { data: spacesData, error: spacesError } = useQuery(
        queryKey,
        async () =>
            await dynamicAPI(
                'get',
                `${url}?page=1&region=${encodeURIComponent(region)}&limit=1000`,
                {}
            ),
        { retry: 0 }
    );

    const navigate = useNavigate();

    // console.log(spacesData);


    return (
        <>
            <Helmet>
                <title>Find Artistic Spaces in Thessaloniki | Galleries, Workshops, Theaters & More</title>
                <meta
                    name="description"
                    content="Discover Thessaloniki's vibrant art scene with our interactive map. Browse a curated collection of galleries, underground spaces, and creative hubs that showcase the city's cultural flair."
                    key="desc"
                />
                <meta
                    property="og:title"
                    content="Find Artistic Spaces in Thessaloniki – A Curated Collection of Galleries, Underground Art & Creative Hubs"
                    key="ogtitle"
                />
                <meta
                    property="og:description"
                    content="Discover Thessaloniki's vibrant art scene with our interactive map. Browse a curated collection of galleries, underground spaces, and creative hubs that showcase the city's cultural flair."
                    key="ogdesc"
                />
                <meta property="og:url" content="https://aart.gr/spaces" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='map-block'>
                <ErrorBoundary
                    FallbackComponent={() => (
                        <ErrorFallback goBack={() => navigate(-1)} error={spacesError} />
                    )}
                    onError={console.error}
                >
                    <span className='map-spaces-container'>
                        <Grid gridRow={1} sx={{width: "100%", height: "100%"}}>
                            <Grid sm={12}>
                                <SpaceForm />
                            </Grid>
                            <Grid sm={12}>
                                {spacesData && <SpaceMap generatePins={spacesData.data}/>}
                            </Grid>
                        </Grid>
                    </span>
                </ErrorBoundary>
            </div>
        </>
    );
}

function ErrorFallback({ error, goBack }) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Maps;