import Navigation from './Navigation'
import Footer from './Footer'

export default function Layout({children, showAdminNavigation = false}) {
    return (
        <>
            {showAdminNavigation && <Navigation/>}
            <main className='container'>
                {children}
            </main>
            {showAdminNavigation && <Footer/>}
        </>
    )
}
