export const languageOptions = [
    {
        value: 'el',
        label: 'Ελληνικά',
        icon: <img src={`${process.env.PUBLIC_URL}/locales/el/el.svg`} alt="Greek" style={{ height: '25px', borderRadius:'5px' }} />
    },
    {
        value: 'en',
        label: 'English',
        icon: <img src={`${process.env.PUBLIC_URL}/locales/en/en.svg`} alt="English" style={{ height: '25px', borderRadius:'5px' }} />
    }
];