import DeleteItemIcon from "../../../assets/delete-item.svg";
import EditItemIcon from "../../../assets/edit-item.svg";

const ViewClientPermissions = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete
}) => {
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <table className='table table-sm table-border'>
        <thead className='border-0'>
        <tr>
          <th>Name</th>
          <th>Menu</th>
          <th>Menu Sorting</th>
          <th>Path</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((clientPermission) => (
                <tr key={clientPermission._id}>
                  <td>{clientPermission.name}</td>
                  <td>{clientPermission.menu}</td>
                  <td>{clientPermission.menu_order}</td>
                  <td>{clientPermission.path}</td>
                  <td>{clientPermission.description}</td>
                  <td>
                    <div className='btn-group'>
                      <button
                          className='btn'
                          onClick={() => editHandler(clientPermission)}
                          data-bs-toggle='modal'
                          data-bs-target='#clientPermissionModal'
                      >
                        <img src={EditItemIcon} alt="Edit"
                             className="icon-img action-icons-img"/>
                      </button>

                      <button
                          className='btn'
                          onClick={() => deleteHandler(clientPermission._id)}
                          disabled={isLoadingDelete}
                      >
                        {isLoadingDelete ? (
                            <span className='spinner-border spinner-border-sm'/>
                        ) : (
                            <span>
        <img src={DeleteItemIcon} alt="Delete"
             className="icon-img action-icons-img"/>
                        </span>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ViewClientPermissions
