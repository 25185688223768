import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Spinner, Message } from '../../../components';
import { useTranslation } from 'react-i18next';
import dynamicAPI from '../../../api/dynamicAPI';
import useTranslationsAPI from '../../../api/translations';
import KeyValueEditor from '../../../utils/KeyValueEditor';
import "../../../css/translationsEditor.css";

const TranslationsEditor = () => {
    const { t, i18n } = useTranslation();
    const defaultLang = i18n.language || 'en';
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLang);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [showRaw, setShowRaw] = useState(false);
    const [rawText, setRawText] = useState('');

    // Use our hook for a specific language's translations.
    const { translationsQuery, updateMutation, generateMutation } = useTranslationsAPI(selectedLanguage);
    const { data, isLoading, isError, error } = translationsQuery;

    // Store the translation data (object) for the selected language.
    const [translationObject, setTranslationObject] = useState({});

    // On mount, fetch all available translations (i.e. all languages).
    useEffect(() => {
        const fetchAllLanguages = async () => {
            try {
                const response = await dynamicAPI('post', '/api/auth/translations', {}); // no lang provided
                if (response.translations) {
                    const langs = response.translations.map(item => item.language);
                    setAvailableLanguages(langs);
                }
            } catch (err) {
                console.error('Error fetching available languages:', err);
            }
        };
        fetchAllLanguages();
    }, []);

    // When data loads for the selected language, update our state.
    useEffect(() => {
        if (data) {
            let extracted = {};
            if (data.langFound === false) {
                // No translation document exists for this language.
                extracted = {};
            } else if (data.langFound === true && data.translation) {
                // Extract the inner "data" object from the nested structure.
                extracted =
                    data.translation.data && data.translation.data.entries
                        ? data.translation.data.entries
                        : {};
            } else if (data.translations) {
                // If backend returns an array (when no lang was provided) try to find the matching one.
                const found = data.translations.find(item => item.language === selectedLanguage);
                extracted =
                    found && found.data && found.data.entries ? found.data.entries : {};
            }
            setTranslationObject(extracted);
            setRawText(JSON.stringify(extracted, null, 2));
        }
    }, [data, selectedLanguage]);

    const handleEditorChange = (updatedObj) => {
        setTranslationObject(updatedObj);
        setRawText(JSON.stringify(updatedObj, null, 2));
    };

    // Raw editor handlers
    const handleRawChange = (e) => {
        setRawText(e.target.value);
    };

    const parseRaw = () => {
        try {
            const parsed = JSON.parse(rawText);
            setTranslationObject(parsed);
            setShowRaw(false); // Optionally switch back to key–value view after parsing
        } catch (err) {
            alert(t('Invalid JSON'));
        }
    };

    const handleSave = () => {
        updateMutation.mutate({ lang: selectedLanguage, entries: translationObject });
        // If saving a new language, add it to the list.
        if (!availableLanguages.includes(selectedLanguage)) {
            setAvailableLanguages([...availableLanguages, selectedLanguage]);
        }
    };

    // Sort the entries by key (alphabetically) and update state.
    const handleSortEntries = () => {
        const sortedObj = Object.keys(translationObject)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, key) => {
                acc[key] = translationObject[key];
                return acc;
            }, {});
        setTranslationObject(sortedObj);
        setRawText(JSON.stringify(sortedObj, null, 2));
    };

    // Change selected language when a language button is clicked.
    const handleLanguageSelect = (lang) => {
        setSelectedLanguage(lang);
    };

    // When "Add New" is clicked, prompt the user for a new language code.
    const handleAddNewLanguage = () => {
        const newLang = prompt(t('Enter new language code (e.g., el, gr, it, us):'));
        if (newLang) {
            setSelectedLanguage(newLang);
            if (!availableLanguages.includes(newLang)) {
                setAvailableLanguages([...availableLanguages, newLang]);
            }
            // Reset the editor for the new language.
            setTranslationObject({});
            setRawText('{}');
        }
    };

    // New: Handle "Generate Translations" using the hook's generateMutation.
    const handleGenerateTranslations = () => {
        if (!window.confirm(t('Are you sure you want to regenerate translations?'))) {
            return; // User canceled
        }
        generateMutation.mutate();
    };

    // Extract lastGenerated timestamp from the fetched data.
    // Assumes your translation document includes lastGenerated.
    let lastGeneratedDisplay = '';
    if (data && data.langFound === true && data.translation && data.translation.lastGenerated) {
        lastGeneratedDisplay = new Date(data.translation.lastGenerated).toLocaleString();
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{t('Edit Translations')}</title>
                    <meta property="og:title" content={t('Edit Translations')} />
                </Helmet>
            </HelmetProvider>
            <div className="translations-editor container">
                <h2 className="text-center mb-3">{t('Edit Translations')}</h2>

                {/* Language selection bar */}
                <div className="mb-3">
                    <h4>{t('Available Languages')}:</h4>
                    {availableLanguages.map((lang) => (
                        <button
                            key={lang}
                            className={`btn ${selectedLanguage === lang ? 'btn-primary' : 'btn-secondary'} mx-1`}
                            onClick={() => handleLanguageSelect(lang)}
                        >
                            {lang.toUpperCase()}
                        </button>
                    ))}
                    <button className="btn btn-success mx-1" onClick={handleAddNewLanguage}>
                        {t('Add New')}
                    </button>
                    {/* Generate Translations button at top right */}
                    <button className="btn btn-warning float-right" onClick={handleGenerateTranslations}>
                        {t('Generate Translations')}
                    </button>
                    {/* Display last generated timestamp if available */}
                    {lastGeneratedDisplay && (
                        <div className="float-right mr-3" style={{ fontSize: '0.9rem', paddingTop: '8px' }}>
                            {t('Last generated:')} {lastGeneratedDisplay}
                        </div>
                    )}
                </div>

                {isLoading ? (
                    <Spinner />
                ) : isError ? (
                    <Message variant="danger">
                        {error?.message || t('Error loading translations')}
                    </Message>
                ) : (
                    <>
                        {/* Toggle between Raw Editor and Key-Value Editor */}
                        <div className="mb-3 text-center">
                            <button className="btn btn-secondary" onClick={() => setShowRaw(!showRaw)}>
                                {showRaw ? t('Switch to Key-Value Editor') : t('Switch to Raw Editor')}
                            </button>
                            <button className="btn btn-info mx-1" onClick={handleSortEntries}>
                                {t('Sort Entries')}
                            </button>
                        </div>

                        {showRaw ? (
                            <div className="mb-3">
                <textarea
                    rows="10"
                    className="form-control"
                    value={rawText}
                    onChange={handleRawChange}
                />
                                <button className="btn btn-info mt-2" onClick={parseRaw}>
                                    {t('Parse Raw JSON')}
                                </button>
                            </div>
                        ) : (
                            <div className="editor-container mb-3">
                                <KeyValueEditor defaultValues={translationObject} onChange={handleEditorChange} />
                            </div>
                        )}
                        <div className="text-center">
                            <button className="btn btn-primary" onClick={handleSave}>
                                {t('Save Changes')}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default TranslationsEditor;
