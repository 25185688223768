import Navigation from './Navigation'
import Footer from './Footer'

export default function AdminLayout({children, showAdminNavigation = false}) {
    return (
        <>
            {showAdminNavigation && <Navigation/>}
            <main className='container py-2 adminlayout' style={{minHeight: '70vh'}}>
                {children}
            </main>
            {showAdminNavigation && <Footer/>}
        </>
    )
}
