// src/utils/KeyValueEditor.jsx
import React, { useEffect, useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

const KeyValueEditor = ({ defaultValues = {}, onChange }) => {
    // Convert defaultValues object to an array for useFieldArray
    const initialEntries = Object.entries(defaultValues).map(([key, value]) => ({ key, value }));

    // Initialize form with default entries
    const { register, control, watch, reset } = useForm({
        defaultValues: { entries: initialEntries },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'entries',
    });

    // When defaultValues prop changes, reset the form values
    useEffect(() => {
        const newEntries = Object.entries(defaultValues).map(([key, value]) => ({ key, value }));
        reset({ entries: newEntries });
    }, [defaultValues, reset]);

    // Use a ref to store previous value to avoid unnecessary updates
    const prevValuesRef = useRef();

    // Watch the entries array for changes
    const entries = watch('entries');

    useEffect(() => {
        // Convert the entries array back to an object
        const updatedObj = entries.reduce((acc, { key, value }) => {
            if (key.trim()) acc[key] = value;
            return acc;
        }, {});

        // Only call onChange if the updated object is different than the previous one
        if (JSON.stringify(updatedObj) !== JSON.stringify(prevValuesRef.current)) {
            prevValuesRef.current = updatedObj;
            onChange(updatedObj);
        }
    }, [entries, onChange]);

    return (
        <div>
            <table className="table">
                <thead>
                <tr>
                    {/* New Row counter header */}
                    <th>#</th>
                    <th>Key</th>
                    <th>Value</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {fields.map((field, index) => (
                    <tr className="key-value-line-editor" key={field.id}>
                        {/* Row counter cell */}
                        <td>{index + 1}</td>
                        <td>
                <textarea
                    {...register(`entries.${index}.key`)}
                    className="form-control"
                    placeholder="Key"
                    rows={1}
                />
                        </td>
                        <td>
                <textarea
                    {...register(`entries.${index}.value`)}
                    className="form-control"
                    placeholder="Value"
                    rows={1}
                />
                        </td>
                        <td>
                            <button type="button" className="btn btn-danger" onClick={() => remove(index)}>
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button type="button" className="btn btn-secondary" onClick={() => append({ key: '', value: '' })}>
                Add New Entry
            </button>
        </div>
    );
};

export default KeyValueEditor;
