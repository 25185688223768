import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
// import translationEL from '../locales/el/translations.json';
// import translationEN from '../locales/en/translations.json';

const fallbackLng = ["en"];

// const resources = {
//     en: {
//         translation: translationEN
//     },
//     el: {
//         translation: translationEL
//     },
// };


i18n
    .use(Backend) // used to load data from other directory
    .use(LanguageDetector) // detects the current language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            // Path where your translation files are stored in the public folder
            loadPath: '/locales/{{lng}}/translations.json'
        },
        load: 'languageOnly',
        fallbackLng, // default language
        detection: {
            // Options for the language detector
            order: ['localStorage', 'navigator', 'htmlTag'], // Prioritize localStorage, then browser settings, then HTML <tag>
            caches: ['localStorage'], // Cache the selected language in localStorage
            lookupLocalStorage: 'i18nextLng', // Key for storing the language in localStorage
        },
        debug: false,
        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;