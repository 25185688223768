import React, { useState } from "react";
const DefaultMarkerIcon = window.location.origin+"/img/event_icons/default.svg";
import { useTranslation } from 'react-i18next';

function getImageSource(category) {
    if (category) {
        const domain = window.location.origin;
        return `${domain}/img/event_icons/${category}.svg`;
    }
    return DefaultMarkerIcon;
}

const defaultEventsCategoryOptions = [
    // { value: "", label: "No Category" },
    { value: "course", label: "Course" },
    { value: "dance", label: "Dance" },
    { value: "exhibition", label: "Exhibition" },
    { value: "festival", label: "Festival" },
    { value: "film", label: "Film" },
    { value: "live", label: "Live" },
    { value: "meeting", label: "Meeting" },
    { value: "party", label: "Party" },
    { value: "performance", label: "Performance" },
    { value: "standup", label: "Stand-Up" },
    { value: "theater", label: "Theater" },
    { value: "workshop", label: "Workshop" },
];

export const getDefaultEventsCategoryOptions = () => {
    return defaultEventsCategoryOptions;
};

const EventsCategoryFilter = ({ options = defaultEventsCategoryOptions, availableCategories = [], onChange }) => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const { t } = useTranslation();

    const handleCategoryClick = (category) => {
        let updatedCategories;
        if (selectedCategories.includes(category)) {
            updatedCategories = selectedCategories.filter((cat) => cat !== category);
        } else {
            updatedCategories = [...selectedCategories, category];
        }

        setSelectedCategories(updatedCategories);

        if (onChange) {
            onChange(updatedCategories);
        }
    };


    return (
        <div className="events-category-filter">
            <div className="events-category-filter-title"></div>
            <div className="events-category-filter-options">
                {options.map((option) => {
                    const isAvailable = availableCategories.includes(option.value);
                    return (
                        <div
                            key={option.value}
                            className={`events-category-filter-option ${
                                selectedCategories.includes(option.value) ? "selected" : ""
                            } ${!isAvailable ? "not-available" : ""}`}
                            onClick={() => handleCategoryClick(option.value)}
                        >
                            <div className="events-filter-category-title">{t(option.label)}</div>
                            <div className={`events-filter-category-image ${selectedCategories.includes(option.value) ? "selected-icon" : ""}`}>
                                <img src={getImageSource(option.value)} alt={option.label} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EventsCategoryFilter;
