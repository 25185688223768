// src/api/useTranslationsAPI.js
import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';
import { useTranslation } from 'react-i18next';

export default function useTranslationsAPI(language) {
  const { setSuccessMessage, setErrorMessage } = useMessage();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Fetch translations from the API using POST, sending language in the body
  const fetchTranslations = async () => {
    const response = await dynamicAPI('post', `/api/auth/translations`, { lang: language });
    return response.data || response;
  };

  // Update translations via the API using PUT, sending language and data in the body
  const updateTranslations = async (data) => {
    const response = await dynamicAPI('put', `/api/auth/translations`, { lang: language, data });
    return response;
  };

  // Generate translations via the API using POST, sending generate_translations key
  const generateTranslations = async () => {
    // Optionally include the language if your backend expects it
    const response = await dynamicAPI('post', '/api/auth/translations', { generate_files: true});
    return response;
  };

  const translationsQuery = useQuery(['translations', language], fetchTranslations, {
    onError: (error) => {
      setErrorMessage(t('Error fetching translations') + error);
    },
  });

  const updateMutation = useMutation(updateTranslations, {
    onSuccess: (data) => {
      setSuccessMessage(data.message || t('Translations saved successfully'));
      queryClient.invalidateQueries(['translations', language, data]);
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.error || t('Error saving translations');
      setErrorMessage(errorMessage);
    },
  });

  const generateMutation = useMutation(generateTranslations, {
    onSuccess: (data) => {
      setSuccessMessage(data.message || t('Translations generated successfully'));
      queryClient.invalidateQueries(['translations', language]);
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.error || t('Error generating translations');
      setErrorMessage(errorMessage);
    },
  });

  return { translationsQuery, updateMutation, generateMutation };
}
