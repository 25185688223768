import React from 'react';
import {useNavigate} from 'react-router-dom';
import GreeceMapSvgSelect from '../../components/maps/space-map-select';
import '../../css/spaces-map-select.css';
import {Helmet} from 'react-helmet-async';

function SpacesMapSelect() {
    const navigate = useNavigate();

    const handleRegionClick = (region) => {
        navigate(`/spaces?region=${encodeURIComponent(region)}`);
    };

    return (
        <>
            <Helmet>
                <title>Spaces By Region | ArtNet</title>
                <meta
                    name="description"
                    content="Select a region on our interactive map to explore artistic spaces across Greece. Discover galleries, studios, and creative hubs."
                />
                <meta property="og:title" content="Spaces By Region" key="ogtitle"/>
                <meta
                    property="og:description"
                    content="Explore artistic spaces by selecting a region on our interactive map. Find creative hubs and cultural landmarks with ArtNet."
                    key="ogdesc"
                />
                <meta property="og:url" content="https://aart.gr/spaces"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="map-svg-select-block">
                <GreeceMapSvgSelect onRegionClick={handleRegionClick}/>
            </div>
        </>
    );
}

export default SpacesMapSelect;
