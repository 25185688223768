import React from 'react';
import Select from 'react-select';

const AddSpacesToUser = ({ name, errors, dataSpaces, setValue, selectedSpaces, setSelectedSpaces }) => {
    // Handle the change in selection
    const handleSelectChange = (selectedOptions) => {
        setSelectedSpaces(selectedOptions || []);
        const spacesIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setValue(name, spacesIds);
    };

    // Map dataSpaces to options for react-select
    const options = dataSpaces?.data?.map(space => ({
        value: space._id,
        label: space.name,
    })) || [];

    return (
        <div className='mb-3'>
            <label htmlFor='spaces'>Select Spaces</label>
            <Select
                id='spaces'
                options={options}
                isMulti
                onChange={handleSelectChange}
                value={selectedSpaces}
                className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
            />
            {errors[name] && <div className='invalid-feedback'>{errors[name].message}</div>}
        </div>
    );
};

export default AddSpacesToUser;
