import React, {useState} from "react";
// import Select from 'react-select';
const DefaultMarkerIcon = window.location.origin+"/img/map_filters/default.svg";

const getImageSource = (category) => {
    const domain = window.location.origin;
    return category ? `${domain}/img/map_filters/${category}.svg` : DefaultMarkerIcon;
};


const defaultMapsCategoryOptions = [
    {value: '', label: 'No Category'},
    {value: 'dance', label: 'Dance'},
    {value: 'cafe_bar', label: 'Cafe Bar'},
    {value: 'cinema', label: 'Cinema'},
    {value: 'crafting_space', label: 'Crafting Space'},
    {value: 'exhibition_hall', label: 'Exhibition Hall'},
    {value: 'gallery', label: 'Gallery'},
    {value: 'multipurpose', label: 'Πολυχώρος'},
    {value: 'museum', label: 'Μουσείο'},
    {value: 'private_school', label: 'Ιδιωτική σχολή'},
    {value: 'studio', label: 'Studio'},
    {value: 'theater', label: 'Θέατρο'},
    {value: 'university', label: 'University'},
    {value: 'visual_art_studio', label: 'Visual Art Studio'},
];
export const getDefaultCategoryOptions = () => {
    return defaultMapsCategoryOptions;
};


const CategoryFilter = ({
                            options = getDefaultCategoryOptions(),
                            onChange,
                            availableCategories = [],
                            t
}) => {

    const [selectedCategories, setSelectedCategories] = useState([]);
    const handleCategoryClick = (categoryValue) => {
        console.log('FILTERING SELECTED');
        console.log(categoryValue);
        const newSelectedCategories = selectedCategories.includes(categoryValue)
            ? selectedCategories.filter((category) => category !== categoryValue)
            : [...selectedCategories, categoryValue];

        // console.log('WHAT IS SELECTED');
        setSelectedCategories(newSelectedCategories);

        if (onChange) {
            onChange(newSelectedCategories);
        }
    };

    return (
        <div className="space-category-filter">
            {options.map((option) => {
                // Always show the "No Category" option
                const isAvailable = option.value === "" || availableCategories.includes(option.value);
                return (
                    <div
                        key={option.value}
                        className={`space-category-filter-option ${
                            selectedCategories.includes(option.value) ? "selected" : ""
                        } ${!isAvailable ? "category_not_available" : ""}`}
                        onClick={() => handleCategoryClick(option.value)}
                    >
                    <div className="space-filter-category-title">{t(option.label)}</div>
                    <div
                        className={`space-filter-category-image ${selectedCategories.includes(option.value) ? "selected-icon" : ""}`}>
                        <img src={getImageSource(option.value)} alt={option.label}/>
                    </div>
                </div>
                );
            })}
        </div>
    );
};

export default CategoryFilter;