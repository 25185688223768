import React from 'react';
import "../../css/events-view.css";
import dynamicAPI from '../../api/dynamicAPI';
import {useQuery} from 'react-query';
import EventsList from "../../components/events/events-list";
import EventsListForm from "../../components/events/events-list-form";
import { Helmet } from 'react-helmet-async';

function Events() {
    const url = '/api/view/events';
    const q = '';
    const queryKey = 'Events';
    const {data: eventsData, error: eventsError} = useQuery(
        queryKey,
        async () =>
            await dynamicAPI('get', `${url}?page=1&q=${q}&limit=1000`, {}),
        {retry: 0}
    );
    // console.log('heyu');

    // console.log(eventsData);

    // console.log(spacesData);

    // Check if the data has been fetched successfully
    if (eventsError) {
        return <div>Error: {eventsError.message}</div>;
    }

    if (!eventsData || !eventsData.data || !Array.isArray(eventsData.data)) {
        return <div>No events found.</div>;
    }
    return (
        <>
            <Helmet>
                <title>Exhibitions, Workshops, Events, Festivals | ArtNet - Artist Run Initiative</title>
                <meta
                    name="description"
                    content="Explore concerts, festivals, meetups, excibitions, jazz lives and other local or underground events in Thessaloniki & Greece"
                />
                <meta property="og:title" content="Find Music Events, Excibitions, Festivals, Submit your event" key="ogtitle" />
                <meta
                    property="og:description"
                    content="Explore concerts, festivals, meetups, excibitions, jazz lives and other local or underground events in Thessaloniki & Greece"
                    key="ogdesc"
                />
                <meta property="og:url" content="https://aart.gr/events" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="events-block" style={{ width: '100%'}}>
                    <EventsListForm />
                    <EventsList eventsData={eventsData} />
            </div>
        </>
    );
}

export default Events;