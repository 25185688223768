import { Helmet, HelmetProvider } from 'react-helmet-async';
import SettingsDashboardIcon from "../../assets/settings_dashboard.png";
import SpacesDashboardIcon from "../../assets/spaces_dasboard.png";
import EventsDashboardIcon from "../../assets/events_dasboard.png";

import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useUserCategoriesHook from '../../api/userCategories';

const Home = () => {
    const { auth } = useAuth();

    // Use the user categories hook to fetch categories
    const { getGuestCategories, getAdminCategories } = useUserCategoriesHook({});

    // Fetch appropriate categories based on auth state
    const { data: categoriesData, isLoading } = auth?.userInfo ? getAdminCategories : getGuestCategories;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Extract available categories from the fetched data
    const categories = categoriesData?.categories || [];

    // Helper function to check if a path exists in the user's categories
    const hasCategoryPath = (path) => categories.some(category => category.path === path);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ArtNet Project</title>
                    <meta property='og:title' content='Home Page' key='title' />
                </Helmet>
            </HelmetProvider>

            <div className='d-flex justify-content-center align-items-center' style={{ height: '30vh' }}>
                <span className='text-muted'>
                    <h3 className='home-animation-h3'>
                        <span className='home-animation-span'>ArtNet</span>
                        <span className='home-animation-span'>Project</span>
                    </h3>
                </span>
            </div>

            {/* Icon Section */}
            <div className='container mt-4'>
                <div className='row justify-content-center'>

                    {/* Conditionally render the Orders Icon with Title if the user has access to /admin/auth/orders */}
                    {hasCategoryPath('/admin/auth/spaces') && (
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="text-center dashboard-main-icon-block mb-4">
                                <Link to='/admin/auth/spaces' className='dashboard-main-icon'>
                                    <img
                                        src={SpacesDashboardIcon}
                                        alt='Spaces Dashboard Icon'
                                        className='img-fluid'
                                    />
                                </Link>
                                <p className='dashboard-main-icon-title'>Spaces</p>
                            </div>
                        </div>
                    )}

                    {/* Conditionally render the Machines Icon with Title if the user has access to /admin/auth/machines */}
                    {hasCategoryPath('/admin/auth/events') && (
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="text-center dashboard-main-icon-block mb-4">
                                <Link to='/admin/auth/events' className='dashboard-main-icon'>
                                    <img
                                        src={EventsDashboardIcon}
                                        alt='Events Dashboard Icon'
                                        className='img-fluid'
                                    />
                                </Link>
                                <p className='dashboard-main-icon-title'>Events</p>
                            </div>
                        </div>
                    )}

                    {/* Conditionally render the UserProfile Icon with Title if the user has access to /admin/auth/account/profile */}
                    {hasCategoryPath('/admin/auth/account/profile') && (
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="text-center dashboard-main-icon-block mb-4">
                                <Link to='/admin/auth/account/profile' className='dashboard-main-icon'>
                                    <img
                                        src={SettingsDashboardIcon}
                                        alt='UserProfile Dashboard Icon'
                                        className='img-fluid'
                                    />
                                </Link>
                                <p className='dashboard-main-icon-title'>UserProfile</p>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}

export default Home;
